<script setup>
import { ref, reactive, defineProps, defineEmits, computed, watch } from 'vue'
import { createSortList, editSortList, deleteSortList } from '@/services/appreciation'
import { uploadFileToOss } from '@/utils/oss'
import { PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  current: {
    type: Object
  },
  treeData: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['onSave'])

const formRef = ref()

const pageState = reactive({
  uploadLoading: false
})

const fileList = ref([])

const labelCol = { span: 4 }
const wrapperCol = { span: 17 }

const formState = computed(() => props.current)

watch(() => formState.value, (val) => {
  if (val.cover_image_url) {
    fileList.value = [{ url: val.cover_image_url, status: 'done' }]
  } else {
    fileList.value = []
  }
  formState.value.pid = val.path
})

const title = computed(() => props.current?.id ? '编辑分类' : '新建分类')

const treeData = computed(() => props.treeData)

const handleReset = () => {
  emit('onSave')
  pageState.title = '新建分类'
  pageState.uploadLoading = false

  formState.value = { pid: [0] }
  fileList.value = []
}

const handleSave = async () => {
  await formRef.value.validate()
  try {
    formState.value.cover_image_url = fileList.value[0]?.url || ''
    const params = {}
    params.name = formState.value.name
    params.cover_image_url = formState.value.cover_image_url
    params.order_index = formState.value.order_index
    params.id = formState.value.id
    // params.pid = formState.value.pid?.at(-1) ?? 0
    params.pid = formState.value.pid ? formState.value.pid[formState.value.pid.length - 1] ?? 0 : 0

    if (params.id) {
      await editSortList(params.id, params)
    } else {
      await createSortList(params)
    }

    message.success('保存成功')
    handleReset()
  } catch (e) {
    message.success('保存失败')
    console.error(e)
  }
}

const handleDelete = async () => {
  try {
    await deleteSortList(formState.value.id)
    message.success('删除成功')
    handleReset()
  } catch (e) {
    message.error('删除失败')
    console.error(e)
  }
}

const goBack = () => {
  router.go(-1)
}

const handleUpload = (info) => {
  const { file } = info
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    try {
      uploadFileToOss(file).then(data => {
        const { url } = data
        if (fileList.value.length > 1) {
          fileList.value.shift()
        }
        fileList.value.forEach(item => {
          if (item.name === file.name) {
            item.url = url
            item.status = 'done'
          }
        })
      })
    } catch (e) {
      console.error(e)
    }
  }
}

</script>
<template>
  <a-card  bordered :title="title" :style="{height:'100%'}">
    <template #extra>
      <a-button type="primary" @click="handleSave">保存</a-button>
      <a-button v-show="formState?.id" type="danger" @click="handleDelete" style="margin-left: 8px">删除</a-button>
      <a-button @click="goBack" style="margin-left: 8px">返回</a-button>
    </template>

    <a-form
      ref="formRef"
      :model="formState"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-form-item
        name="name"
        label="分类名称"
        :rules="[{ required: true, message: '请输入分类名称' }]"
      >
        <a-input v-model:value="formState.name" />
      </a-form-item>

      <a-form-item
        name="order_index"
        label="排序"
      >
        <a-input v-model:value="formState.order_index" />
      </a-form-item>

      <a-form-item
        name="cover_image_url"
        label="封面"
      >
        <a-upload
          v-model:file-list="fileList"
          list-type="picture-card"
          accept=".png, .jpg, .jpeg"
          :customRequest="handleUpload"
        >
          <plus-outlined />
          <div class="ant-upload-text">上传图片</div>
        </a-upload>
      </a-form-item>

      <a-form-item
        name="pid"
        label="选择父级分类"
      >
        <a-cascader
          :allowClear="false"
          :defaultValue="[0]"
          v-model:value="formState.pid"
          :options="treeData"
          placeholder="请选择父级分类"
          expandTrigger="hover"
          change-on-select
          :fieldNames="{value:'key', label:'title', children:'children'}"
        />
      </a-form-item>
    </a-form>
  </a-card>
</template>
<style lang="less" scoped>

</style>
