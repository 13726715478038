<script setup>
import { ref, reactive, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { getSortList } from '@/services/appreciation'
import Edit from './Edit'

const router = useRouter()

const pageState = reactive({})

const current = ref({})

const treeState = reactive({
  data: null,
  expandedKeys: [],
  nodeList: []
})

const getData = async () => {
  const { items } = await getSortList()
  treeState.nodeList = items
  const data = getTreeData(0, items)
  treeState.data = [{ key: 0, title: '根节点', children: data }]
}

// 构造菜单树数据
const getTreeData = (pid, menus) =>
  menus
    .filter(item => item.pid === pid)
    .map(item => {
      if (menus.filter(menu => menu.pid === item.id).length === 0) {
        return { title: item.name, key: item.id }
      }
      if (pid === 0) {
        treeState.expandedKeys.push(item.id.toString())
      }
      return {
        title: item.name,
        key: item.id,
        children: getTreeData(item.id, menus)
      }
    })

const handleCreate = () => {
  current.value = {}
}

const handleFormSave = () => {
  current.value = {}
  getData()
}
const getNodeFullPath = (id, path = []) => {
  const node = treeState.nodeList.filter(i => id === i.id)[0] ?? {}
  if (node.pid >= 0) {
    path.unshift(node.pid)
    return getNodeFullPath(node.pid, path)
  }
  return path
}

const handleSelectNode = (node, e) => {
  if (node[0] === 0) {
    current.value = {}
  } else {
    current.value = {
      ...treeState.nodeList.filter(i => node[0] === i.id)[0] ?? {},
      path: getNodeFullPath(node[0])
    }
  }
}

onBeforeMount(() => {
  getData()
})

</script>
<template>
  <div class="main">
    <div class="left">
      <a-card  bordered title="赏析资源分类" :style="{height:'100%'}">
        <template #extra>
          <a-button type="primary" @click="handleCreate">添加分类</a-button>
        </template>
        <a-tree
          :expandedKeys="treeState.expandedKeys"
          @select="handleSelectNode"
          :tree-data="treeState.data"
          v-model:expandedKeys="treeState.expandedKeys"
        >
          <template #title0010><span style="color: #1890ff">sss</span></template>
        </a-tree>
      </a-card>
    </div>
    <div class="right">
      <edit :current="current" :tree-data="treeState.data" @onSave="handleFormSave" />
    </div>
  </div>
</template>
<style lang="less" scoped>
.main{
  height: 100%;
  display: flex;
}
.left{
  flex: 1;
  height: 100%;
  padding: 24px;
}
.right{
  flex: 2;
  padding: 24px;
}
</style>
