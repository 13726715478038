import { getRequest, postRequest, deleteRequest } from '@/utils/http'

// 获取教师分享到平台的分享记录
export const getAppreciationList = (params: any): any => {
  return getRequest('/official/famous-music-songs', params)
}

export const createAppreciation = (params: any): any => {
  return postRequest('/official/famous-music-song/create', params)
}

export const editAppreciation = (id: number, params: any): any => {
  return postRequest(`/official/famous-music-song/${id}/edit`, params)
}

export const getAppreciationInfo = (id: number): any => getRequest(`/official/famous-music-song/${id}`)

export const deleteAppreciation = (id: number): any => deleteRequest(`/official/famous-music-song/${id}`)

// 获取分类列表
export const getSortList = () => {
  return getRequest('/official/famous-music-song-categories', { per_page: 9999 })
}
// 创建分类
export const createSortList = (params: any) => {
  return postRequest('/official/famous-music-song-category/create', params)
}
// 编辑分类
export const editSortList = (id: number, params: any) => {
  return postRequest(`/official/famous-music-song-category/${id}/edit`, params)
}
// 删除分类
export const deleteSortList = (id) => {
  return deleteRequest(`/official/famous-music-song-category/${id}`)
}
